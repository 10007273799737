<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h4 class="font-weight-bold text-success mb-4">
              Retreat Stay Preferences
            </h4>

            <p class="alert alert-success">
              Select your retreat stay preferences, water, pillows, morning
              paper, fruits and juices.
            </p>

            <div class="row mb-4">
              <div class="col">
                <label for="">Water preferences</label>
                <select v-model="form.water" class="form-control">
                  <option value="" disabled>Select water type</option>
                  <option value="still">Still</option>
                  <option value="sparkling">Sparkling</option>
                  <option value="none">No Water</option>
                </select>
              </div>

              <div class="col">
                <label for="">Pillow preferences</label>
                <select v-model="form.pillow" class="form-control">
                  <option value="" disabled>Select pillow</option>
                  <option value="feather">Feather</option>
                  <option value="hypoallergenic">Hypoallergenic</option>
                  <option value="down_alternative">Down Alternative</option>
                  <option value="memory">Memory Foam</option>
                </select>
              </div>
            </div>

            <!--  -->

            <div class="row mb-4">
              <div class="col">
                <label for="">Morning Paper</label>
                <select v-model="form.paper" class="form-control">
                  <option value="" disabled>Select morning paper</option>
                  <option value="dailymail">Daily Mail</option>
                  <option value="telegraph">Daily Telegraph</option>
                  <option value="times">Times</option>
                  <option value="lemonde">Le Monde</option>
                  <option value="guardian">Guardian</option>
                  <option value="financialtimes">Financial Times</option>
                </select>
              </div>

              <div class="col">
                <label for="">Fruits / Juices</label>
                <select v-model="form.fruits" class="form-control">
                  <option value="" disabled>Select fruits / juices</option>

                  <optgroup title="Juices">
                    <option value="orange">Orange Juice</option>
                    <option value="grapefruit">Grapefruit</option>
                    <option value="tomato">Tomato</option>
                  </optgroup>

                  <optgroup title="Fruits">
                    <option value="bowl">Bowl of Fruit</option>
                    <option value="none">No Fruit</option>
                  </optgroup>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-auto ms-auto">
                <button class="btn btn-success">
                  <i class="far fa-check me-2"></i>Save preferences
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        water: "",
        pillow: "",
        paper: "",
        fruits: "",
      },
    };
  },
};
</script>

<style></style>
